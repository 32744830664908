.custom-loading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.btn {
    padding: 5px 15px;
}

.navbar-scrolltofixed,
.menuzord-menu ul.dropdown,
.menuzord-menu ul.dropdown li ul.dropdown {
    box-shadow: rgb(238 238 238) 0 0 10px !important;
}

.progress-bar {
    max-width: 100%;
}

.total-amount {
    border: solid 2px #00830a;
    border-radius: 5px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.total-amount b {
    font-weight: bold;
    color: green;
    padding-left: 5px;
    font-size: 2rem;
}


.tp-caption {
    font-size: 30px;
}

.logo {
    width: 7rem;
}

.lecturers-area .owl-carousel .owl-item img {
    width: 100%;
    height: 20rem;
    object-fit: contain;
}


footer .footer-box img {
    height: 7rem;
    width: 90%;
    border-radius: 5px;
}

footer .box-first img {
    height: auto;
    width: auto;
    border-radius: 5px;
}


.logo-mobile-menu img {
    width: 7rem;
}

.whatsapp-section {
    position: fixed;
    bottom: 8%;
    right: 1%;
    width: 5rem;
    z-index: 998;
}

.custom-studentreg-div .inner-page-banner-area {
    display: none;
}

.custom-studentreg-div .registration-page-area {
    z-index: 99;
    background-color: white;
    width: 30%;
    position: absolute;
    right: 1%;
    top: 1%;
}

#ensign-nivoslider-3 img {
    height: 70rem !important;
    object-fit: cover;
}

#ensign-nivoslider-3 img:nth-child(2) {
    height: 70rem !important;
    object-fit: contain !important;
}

.main-page-strip {
    padding: 2rem;
    position: fixed;
    top: 15%;
    background-color: #fff;
    z-index: 99;
    width: 100%;
    box-shadow: 0 0 4px #000;
    width: 20%;
    right: 0;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.main-page-strip h2 {
    font-size: 1.6rem;
}

.main-page-strip label,
.main-page-strip input,
.main-page-strip button {
    font-size: 1.2rem;
}

.main-page-strip input {
    height: 30px !important;
}

.main .register-now {
    border: solid 1px #fdc800;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    font-size: 1.4rem;
    padding: 1rem 2rem;
    width: auto;
    background-color: #fdc800;
    color: black;
    letter-spacing: 0.5px;
    cursor: pointer;
}

.main .fa-close {
    position: absolute;
    right: 10px;
    cursor: pointer;

    width: 25px;
    height: 25px;
    border: solid 1px#ccc;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    color: #fac802;
}

p,
ul li {
    font-size: 1.5rem;
}

.tools-covered-section {
    display: flex;
    flex-wrap: wrap;
}

.tools-covered-section li {
    padding: 2rem;
}

.accordion-collapse ul li {
    padding: 1rem 0;
    font-size: 1.6rem;

}

.memories-video {
    width: 560px;
    height: 315px;
}

#scrollUp {
    display: none !important;
}

.sidebar {
    top: 12rem;
    position: sticky;
    z-index: 9;
}

.title-container p {
    font-size: 2.3rem !important;
}

.customer-speak {
    width: 80%;
    height: 40rem;
}

.software-engineering-approach-wrapper {
    width: 100%;
    border: solid 1px#eee;
    padding: 1rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.software-engineering-approach-wrapper img {
    width: 100%;
}

.theme-color-text {
    color: #fac802 !important;
}

.client-name-text {
    font-size: 2rem;
    color: white;
    font-weight: bold;
    text-align: center;
    margin: 0 auto;
}

.single-item-wrapper .item-content,
.about-box p,
.event-content-holder p {
    display: -webkit-box;
    max-width: auto;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.react-tabs__tab--selected {
    background: #bb0b0c !important;
    border-color: #aaa !important;
    color: #ffffff !important;
}

.react-tabs__tab-list {
    border-bottom: 1px solid #aaa !important;
    margin: 0 !important;
    padding: 0 !important;
    background-color: white !important;
}

.react-tabs__tab {
    padding: 1rem 2rem !important;
}

.react-tabs__tab-panel .rs-breadcrumbs {
    display: none;
}

.article ul,
.article ol {
    list-style: inside !important;
}

.article p,
.article li {
    font-size: 2rem;
    padding: 1rem;
}

.article h3 {
    font-size: 3rem;
}

.article strong {
    font-size: 2.5rem;
}


@media (max-width: 480px) {
    .whatsapp-section {
        bottom: 10%;
    }

    .customer-speak {
        width: 100%;
        height: 40rem;
    }

    .main-page-strip {
        width: 70%;
        top: auto;
        bottom: 0;
        z-index: 999;
    }

    #ensign-nivoslider-3 img {
        height: auto !important;
        object-fit: contain !important;
    }

    .memories-video {
        width: 100%;
        height: 315px;
    }

    #scrollUp {
        display: none;
    }

    .sidebar {
        top: auto;
        position: auto;
        z-index: auto;
    }

}

video {
    position: relative;
    bottom: 20px;
}

.box {
    background-color: white;
    padding: 8px 20px;
    border: 1px solid #ccc;
    border-radius: 12px;
}

.w-70 {
    width: 70%;
}

.header-logo img {
    width: 38%;
}

@media (max-width: 480px) {
    .header-logo img {
        width: 100%;
    }
}

iframe {
    display: none;
}